<template>
  <div>
    <b-spinner
      v-if="!loaded"
      style="width: 3rem; height: 3rem;"
      variant="success"
      label="Loading..."
      class="text-center"
    />
    <div v-if="loaded">
      <b-row>
        <b-col
          xl="3"
        >
          <b-card>
            <b-media>
              <template #aside>
                <b-img
                  :src="require('@/assets/images/icons/dashboard-clients.svg')"
                />
              </template>
              <h2 class="mt-0">
                {{ totalClients }}
              </h2>
              <small>
                Total Number of Clients
              </small>
            </b-media>
          </b-card>
        </b-col>
        <b-col
          xl="3"
        >
          <b-card>
            <b-media>
              <template #aside>
                <b-img
                  :src="require('@/assets/images/icons/dashboard-projects.svg')"
                />
              </template>
              <h2 class="mt-0">
                {{ totalProjects }}
              </h2>
              <small>
                Total Number of Projects
              </small>
            </b-media>
          </b-card>
        </b-col>
        <b-col
          xl="3"
        >
          <b-card>
            <b-media>
              <template #aside>
                <b-img
                  :src="require('@/assets/images/icons/dashboard-locations.svg')"
                />
              </template>
              <h2 class="mt-0">
                {{ totalLocations }}
              </h2>
              <small>
                Total Locations Covered
              </small>
            </b-media>
          </b-card>
        </b-col>
        <b-col
          xl="3"
        >
          <b-card>
            <b-media>
              <template #aside>
                <b-img
                  :src="require('@/assets/images/icons/dashboard-capacity.svg')"
                />
              </template>
              <h2 class="mt-0">
                {{ totalCapacity }}
              </h2>
              <small>
                Total Installed Capacity
              </small>
            </b-media>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col xl="7">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">
              Best Selling Products
            </h5>
            <small>
              <b-link
                class="text-secondary"
                style="text-decoration: underline"
                to="/products"
              >
                View all products
              </b-link>
            </small>
          </div>
          <b-card
            v-if="productsTable"
            no-body
            class="card-company-table"
          >
            <b-table
              :items="productsTable"
              responsive
              :fields="productsField"
              class="mb-0"
            >
              <template #cell(model)="data">
                {{ data.item.model }}
              </template>

              <template #cell(salesCount)="data">
                {{ data.item.salesCount }}
              </template>

              <template #cell(price)="data">
                &#8358;{{ data.item.price.toLocaleString() }}
              </template>

              <template #cell(total)="data">
                &#8358;{{ (data.item.salesCount * parseFloat(data.item.price)).toLocaleString() }}
              </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col xl="5">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">
              Today's Weather
            </h5>
          </div>
          <b-card
            style="background: linear-gradient(91.05deg, #6DCBFC 2.11%, rgba(109, 203, 252, 0.35) 109.7%); background-blend-mode: multiply; box-shadow: 0px 4px 20px 3px rgba(127, 63, 152, 0.05); border-radius: 5px;"
          >
            <b-row>
              <b-col cols="4">
                <div class="mt-2 text-center pl-xl-2">
                  <h5 class="mb-2 text-white">
                    Pretty Sunny
                  </h5>
                  <h1 class="display-2 text-white ml-n1">
                    27<sup>&#176;</sup>
                  </h1>
                </div>
              </b-col>
              <b-col cols="4">
                <div class="mt-2 text-center pl-xl-2">
                  <p
                    class="mt-4 mb-1 text-white"
                    style="font-size: 11px"
                  >
                    Tuesday 3 August
                  </p>
                  <p
                    class="text-white"
                    style="font-size: 11px"
                  >
                    <b-img
                      :src="require('@/assets/images/icons/weather-icon.svg')"
                      alt="logo"
                    />
                    Abuja
                  </p>
                </div>
              </b-col>
              <b-col cols="4">
                <b-img
                  fluid
                  :src="require('@/assets/images/icons/weather-image.svg')"
                  alt="logo"
                  class="mt-2"
                />
              </b-col>
            </b-row>

          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="10">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">
              Top Clients
            </h5>
            <small>
              <b-link
                class="text-secondary"
                style="text-decoration: underline"
                to="/clients"
              >
                View all clients
              </b-link>
            </small>
          </div>
          <b-card
            v-if="clientsTable"
            no-body
            class="card-company-table"
          >
            <b-table
              :items="clientsTable"
              responsive
              :fields="clientsField"
              class="mb-0"
            >
              <template #cell(name)="data">
                <div class="text-nowrap">
                  {{ data.item.name }}
                </div>
                <!--              &#8358;{{ data.item.total.toLocaleString() }}-->
              </template>
              <template #cell(address)="data">
                <div class="text-nowrap">
                  {{ data.item.address }}
                </div>
              </template>
              <template #cell(phone)="data">
                <div class="text-nowrap">
                  {{ data.item.phone }}
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>
        <b-col xl="2">
          <b-card
            class="dashboard-img-1"
            style="margin-top: 31px"
          >
            <b-img
              center
              :src="require('@/assets/images/logo/dashboard-logo.svg')"
            />
            <b-img
              class="mt-3"
              center
              :src="require('@/assets/images/logo/dashboard-tag-line.svg')"
            />
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      loaded: false,
      totalClients: 0,
      totalProjects: 0,
      totalLocations: 0,
      totalCapacity: 0,
      productsField: [
        { key: 'productname', label: 'Product Model' },
        { key: 'salesCount', label: 'Qty' },
        // { key: 'price', label: 'Price' },
        // { key: 'total', label: 'Total' },
      ],
      clientsField: [
        { key: 'clientname', label: 'Name' },
        { key: 'address', label: 'Address' },
        { key: 'phone', label: 'Phone' },
        { key: 'email', label: 'Email' },
        { key: 'clienttype', label: 'Client Type' },
      ],
      productsTable: [],
      clientsTable: [],
    }
  },
  mounted() {
    document.getElementById('custom-breadcrumb').innerHTML = 'Overview'
  },
  created() {
    this.getAdminDashboard()
  },
  methods: {
    getAdminDashboard() {
      this.$store.dispatch('auth/getAdminDashboard').then(res => {
        const { data } = res.data
        console.log(data, 'dsjsdjs')

        
        this.totalClients = data.total_clients
        this.totalProjects = data.total_projects
        this.totalLocations = data.total_locations
        this.totalCapacity = data.total_installed_capacities
        this.productsTable = data.bestselling
        this.clientsTable = data.topclient
        this.loaded = true
      })
    },
  },
}
</script>

<style>
  .dashboard-img-1 {
    background-image: url("../../assets/images/pages/dashboard-img-1.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
